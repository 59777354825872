import React from "react";
import './App.css';

// CONSTANTS

const CONTRACT_URL = "https://etherscan.io/address/0x5755ab845ddeab27e1cfce00cd629b2e135acc3d";
const OPENSEA_URL = "https://opensea.io/collection/larva-lads";
const TWITTER_URL = "https://twitter.com/larvalads";
const LANTON_URL = "https://twitter.com/lantonmills";
const BLUR_URL = "https://magiceden.io/collections/ethereum/0x5755ab845ddeab27e1cfce00cd629b2e135acc3d";
const DISCORD_URL = "https://discord.gg/xr9C25Aa3F";

function App() {


  return (
    <div className="flex items-center justify-center h-screen">

      <div className="w-128">
          <img src="preview.svg" className="mr-auto ml-auto h-auto w-128 mb-9" alt="Larva Lads Preview"></img>
          <h1 className="text-2xl text-pink font-bold mb-4">Larva Lads</h1>
          <p className="text-sm mb-6"><a className="underline" href={OPENSEA_URL} target="_blank" rel="noreferrer">OpenSea</a> ∙ <a className="underline" href={BLUR_URL} target="_blank" rel="noreferrer">Magic Eden</a> ∙ <a className="underline" href={CONTRACT_URL} target="_blank" rel="noreferrer">Etherscan</a> ∙ <a className="underline" href={TWITTER_URL} target="_blank" rel="noreferrer">Twitter</a> ∙ <a className="underline" href={DISCORD_URL} target="_blank" rel="noreferrer">Discord</a></p>
          <p className="mb-6 text-base">Larva Lads are a play on the CryptoPunks and their creators, Larva Labs. The artwork and metadata are fully on-chain and were randomly generated at mint.</p>
          <p className="mb-6 text-base">Larva Lads were created by <a className="underline" href={LANTON_URL} target="_blank" rel="noreferrer">Lanton Mills</a> and were released (for free) in December 2021. They are CC0 public domain, and you can feel free to use them in any way you want.</p>

      </div>

    </div>
  );
};

export default App;


